import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const sliderMv = new Swiper('.js-mv-slider ', {
	loop: true,
	effect: 'fade',
	speed: 2000,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
	},
});

const slider1 = new Swiper('.js-slider01', {
	loop: true,
	slidesPerView: 1.184,
	spaceBetween: 10,
	centeredSlides: true,
	speed: 4e3,
	autoplay: {
		delay: 0,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
		768: {
			slidesPerView: 3.98,
			spaceBetween: 30,
		},
	},
});
