import $ from 'jquery';

$(function () {
	// お問い合わせ種別選択時
	$('input[type=radio]').parent('label').addClass('c-form1__radio');
	$('input[type=radio]').on('click', function () {
		$('.c-form1__radio').removeClass('checked');
		if ($(this).prop('checked', true)) {
			$(this).parent('.c-form1__radio').addClass('checked');
		}
	});

	// ロード時
	$('.c-form1__radio').each(function () {
		if ($(this).children('input[type=radio]').prop('checked') == true) {
			$(this).addClass('checked');
		} else {
			$(this).removeClass('checked');
		}
	});

	// エラー箇所までスクロール
	if ($('.c-form1__row').hasClass('u-error')) {
		let scroll = $('.u-error').offset().top;
		$('html,body').animate({ scrollTop: scroll - 300 }, 1000);
		return false;
	}
});
