import $ from 'jquery';

$(function () {
	if ($('.js-fadein').length) {
		const hWindow = $(window).innerHeight() / 4;

		$('.js-fadein').each(function () {
			const _this = $(this);

			$(window).on('scroll load', function () {
				if (
					$(window).scrollTop() >
					_this.offset().top - $(window).innerHeight() + hWindow
				) {
					_this.addClass('is-on');
				}
			});
		});
	}
});

$(function () {
	if ($('.js-fixed').length) {
		$('.js-fixed').each(function () {
			const _this = $(this);
			const offsetTop = _this.offset().top;

			$(window).on('scroll load', function () {
				const hHeader = 215;

				if ($(window).outerWidth() > 767) {
					if ($(window).scrollTop() > offsetTop - hHeader) {
						_this.addClass('is-on');
					} else {
						_this.removeClass('is-on');
					}
				}
			});

			$(window).on('resize', function () {
				if ($(window).outerWidth() < 768) {
					$('.js-fixed').removeClass('is-on');
				}
			});

			let lastScrollLeft = 0;
			$(window).scroll(function () {
				let documentScrollLeft = $(document).scrollLeft();
				if (lastScrollLeft != documentScrollLeft) {
					lastScrollLeft = documentScrollLeft;
					_this.css('left', -$(window).scrollLeft());

					if (lastScrollLeft === 0) {
						_this.css('left', 'auto');
					}
				}
			});
		});
	}
});
