import $ from 'jquery';

$('a[href*=\\#]:not([href=\\#])').click(function () {
	if (
		location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
		location.hostname == this.hostname
	) {
		let $target = $(this.hash);
		$target =
			($target.length && $target) || $('[name=' + this.hash.slice(1) + ']');
		let offsetTop = $('.c-header').height();
		if ($target.length) {
			let targetOffset = $target.offset().top - offsetTop;
			$('html,body').animate({ scrollTop: targetOffset }, 1000);
			return false;
		}
	}
});

$('.js-gotop').on('click', function (e) {
	e.preventDefault();
	$('html, body').animate({ scrollTop: 0 }, 1000);
});

$(function () {
	$(window).scroll(function () {
		var scroll = $(window).scrollTop();

		if (scroll >= 500) {
			$('.js-gotop').addClass('is-show');
		} else {
			$('.js-gotop').removeClass('is-show');
		}
	});
});
