import $ from 'jquery';
// import { bodyFix, bodyFixReset } from './_BodyFixed.js';

let $window = $(window);

const body = $('body');
const bodyHTML = $('html, body');
let scrollPosi = 0

function bodyFix() {
	scrollPosi = $(window).scrollTop();
	body.addClass('is-fixed').css({ top: -scrollPosi });
}

function bodyFixReset() {
	body.removeClass('is-fixed').css({ top: '0' });
	bodyHTML.scrollTop(scrollPosi);
}

/* ======================================
Detect ipad or smartphone
====================================== */
function iPad() {
	return [
		'iPad Simulator',
		'iPad',
	].includes(navigator.platform)
		|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

$(function () {
	if (iPad()) {
		$('body').addClass('is-ipad')

		// if ($('meta[name="viewport"]').length) {
		// 	$('meta[name="viewport"]').remove()
		// }

	} else {
		// let meta = document.querySelector('meta[name="viewport"]');

		// if (!meta) {
		// 	meta = document.createElement('meta');
		// 	meta.setAttribute('name', 'viewport');
		// }

		// if ($(window).outerWidth() < 768) {
		// 	meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no');

		// } else {
		// 	meta.setAttribute('content', 'width=device-width, initial-scale=1.0');
		// }

		// document.getElementsByTagName('head')[0].appendChild(meta);
	}
})

/* ======================================
header dark
====================================== */
$window.scroll(function () {
	if ($('.page-top').length) {
		let scroll = $window.scrollTop();
		let heightMV = $('.js-mv').outerHeight();

		if (scroll > heightMV) {
			$('.js-header').addClass('is-dark');
		} else {
			let flag =
				!$('.c-modal').hasClass('is-active') &&
				!$('.js-header-nav').hasClass('is-open');
			if (flag) {
				$('.js-header').removeClass('is-dark');
			}
		}
	}
});

/* ======================================
header scroll left
====================================== */
$($window).on('load scroll', function () {
	$('.js-header').css('left', -$window.scrollLeft());
});

/* ======================================
SP menu
====================================== */
$(function () {
	$('.js-header-open').on('click', function () {
		$('.js-header-nav').toggleClass('is-open');
		if ($('.js-header-nav').hasClass('is-open')) {
			bodyFix();
		} else {
			bodyFixReset();
		}
	});

	$('.js-header-close').on('click', function () {
		$('.js-header-nav').removeClass('is-open');
		bodyFixReset();
	});
});
