import $ from 'jquery';

$(function () {
	const tabs = $('.js-tab1')

	if (tabs.length) {
		tabs.each(function () {
			const _thisTab = $(this)
			const contents = _thisTab.find('[data-tab-content]')
			const btns = _thisTab.find('[data-tab-btn]')

			btns.on('click', function () {
				const data = $(this).attr('data-tab-btn')
				const content = $(this).parents(_thisTab).find(`[data-tab-content=${data}]`)

				btns.parent().removeClass('is-active')
				$(this).parent().addClass('is-active')
				contents.removeClass('is-active')
				content.addClass('is-active')
			})
		})
	}
})
