import $ from 'jquery';

$(function () {
	if ($('.js-mv').length) {
		if ($(window).outerWidth() < 768) {
			let height = $(window).height();
			$('.js-mv').css('height', height);
		}
	}
});

let lastScrollLeft = 0;
$(window).scroll(function () {
	let documentScrollLeft = $(document).scrollLeft();
	if (lastScrollLeft != documentScrollLeft) {
		lastScrollLeft = documentScrollLeft;
		$('.js-mv-slider').css('left', -$(window).scrollLeft());
		$('.c-mv1__ttljp').css('left', -$(window).scrollLeft());
		$('.p-top1__ttl').css('left', -$(window).scrollLeft());

		if (lastScrollLeft === 0) {
			$('.c-mv1__ttljp').css('left', 'auto');
			$('.p-top1__ttl').css('left', 'auto');
		}
	}
});
