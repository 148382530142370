import $ from 'jquery';
// import { bodyFix, bodyFixReset } from './_BodyFixed.js';

const body = $('body');
const bodyHTML = $('html, body');
let scrollPosi = 0

function bodyFix() {
	scrollPosi = $(window).scrollTop();
	body.addClass('is-fixed').css({ top: -scrollPosi });
}

function bodyFixReset() {
	body.removeClass('is-fixed').css({ top: '0' });
	bodyHTML.scrollTop(scrollPosi);
}


$(window).on('load', () => {
	const btnOpenModal = $('.js-open-modal');
	const btnCloseModal = $('.js-close-modal');

	function closemodal() {
		$('[data-modal]').removeClass('is-active');
		bodyFixReset();
	}

	btnOpenModal.on('click', function (e) {
		e.preventDefault();

		if ($(window).outerWidth() > 767) {
			$(`[data-modal=${$(this).data('open-modal')}]`).addClass('is-active');
			bodyFix();
		}
	});

	btnCloseModal.on('click', () => closemodal());

	$('[data-modal]').on('click', function (e) {
		if ($(e.target).is('.c-modal') || $(e.target).is('.c-modal__wrap')) {
			closemodal();
		}
	});

	$(window).on('scroll', () => {
		$('.c-modal.is-active').css('left', -$(window).scrollLeft());
	});
});
